<template>
  <v-btn
    :color="isSuccess ? 'success' : 'primary'"
    :loading="loading"
    @click="emit('click')"
  >
    <transition name="fade">
      <v-icon v-if="showSuccess" left>
        {{ mdiCheck }}
      </v-icon>
    </transition>
    <slot v-if="!isSuccess">
      Save
    </slot>
    <slot v-if="isSuccess" name="success">
      Saved
    </slot>
  </v-btn>
</template>

<script setup>
import { mdiCheck } from '@mdi/js';
import { ref, watch } from 'vue';

const showSuccess = ref(false);

const emit = defineEmits(['click', 'update:success']);

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  isSuccess: {
    type: Boolean,
    default: false,
  },
});

const toggleSuccess = () => {
  showSuccess.value = true;
  setTimeout(() => {
    showSuccess.value = false;
    emit('update:success', false);
  }, 3000); // 3 seconds
};

watch(props, () => {
  if (props.isSuccess) {
    toggleSuccess();
  }
});
</script>

<style scoped>
.success {
  background-color: green;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
