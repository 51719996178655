<template>
  <v-navigation-drawer
    :value="props.isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-slide-x-transition>
          <v-img
            :src="require('@/assets/logo.svg')"
            max-width="200px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Welcome"
        :to="{ name: 'page-ViewPage', params: { name: 'Welcome' } }"
        :icon="mdiHomeOutline"
      />
      <!-- Sections -->
      <nav-menu-link
        v-for="(section, index) in sections"
        :key="section.id"
        :title="section.name"
        :to="{ name: 'sections-ViewSection', params: { sectionId: section.id } }"
        :icon="mdiBookCheckOutline"
        :data-cy="`nav-menu-link-${index}`"
      />
      <!-- Admin -->
      <nav-menu-section-title title="Admin"></nav-menu-section-title>
      <nav-menu-link
        title="Content"
        :to="{ name: 'content-ManageContent' }"
        :icon="mdiContentSaveEdit"
        data-cy="nav-menu-link-content"
      />
      <nav-menu-link
        title="Checklists"
        :to="{ name: 'playbooks-PlayBooks' }"
        :icon="mdiBookCogOutline"
        data-cy="nav-menu-link-playbooks"
      />
      <nav-menu-link
        title="Organisations"
        :to="{ name: 'admin-ListOrg' }"
        :icon="mdiOfficeBuilding"
        data-cy="nav-menu-link-users"
      />
      <nav-menu-link
        title="Users"
        :to="{ name: 'admin-Users' }"
        :icon="mdiAccountCogOutline"
        data-cy="nav-menu-link-users"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import {
  onMounted, ref,
} from 'vue';
import {
  mdiHomeOutline,
  mdiContentSaveEdit,
  mdiBookCheckOutline,
  mdiAccountCogOutline,
  mdiBookCogOutline,
  mdiOfficeBuilding,
} from '@mdi/js';
import { useRoot, useStore } from '@/use';
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue';
import NavMenuLink from './components/NavMenuLink.vue';

const props = defineProps({
  isDrawerOpen: {
    type: Boolean,
    default: null,
  },
});

const $root = useRoot();
const store = useStore();

const sections = ref([]);

const organization = ref(store.state.organizations.organization);

onMounted(async () => {
  if (organization.value) {
    sections.value = await store.dispatch('sections/LOAD_sectionsForOrgs');
  }
});

// watch(() => store.state.sections.sections, (newVal) => {
//   sections.value = newVal;
// });

$root.$on('organization.changed', async () => {
  if (organization.value) {
    sections.value = await store.dispatch('sections/LOAD_sectionsForOrgs');
  }
});

</script>
