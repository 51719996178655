class BasicForm {
  constructor(storeModule, storeItem, storeItems, store, ext) {
    this.storeModule = storeModule;
    this.storeItem = storeItem;
    this.storeItems = storeItems;

    this.dialog = false;
    this.isLoading = false;

    this.store = store;
    this.ext = ext;

    this.items = [];
    this.item = {};
  }

  refresh = async (payload) => {
    try {
      this.isLoading = true;
      const { storeModule, storeItems } = this;
      this.items = await this.store.dispatch(`${storeModule}/LOAD_${storeItems}`, payload);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  create = () => {
    this.item = {};
    this.dialog = true;
  };

  edit = (item) => {
    this.item = { ...item };
    this.dialog = true;
  };

  remove = async (item, payload) => {
    try {
      console.debug(item);
      this.isLoading = true;
      const { storeModule, storeItem } = this;
      await this.store.dispatch(`${storeModule}/DELETE_${storeItem}`, item.id);
      await this.refresh(payload);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  };

  submit = async (isEditing) => {
    try {
      this.dialog = false;
      this.isLoading = true;
      const { storeModule, storeItem } = this;
      const item = { ...this.item, ...this.ext };
      if (isEditing) {
        await this.store.dispatch(`${storeModule}/UPDATE_${storeItem}`, item);
      } else {
        await this.store.dispatch(`${storeModule}/CREATE_${storeItem}`, item);
      }
      await this.refresh();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  };

// refresh();
}

export default BasicForm;
