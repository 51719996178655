/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// import Cookies from 'js-cookie';

// Store functionality
import modules from './modules';

// const vuexCookie = new VuexPersistence({
//   // eslint-disable-next-line no-unused-vars
//   restoreState: (key, storage) => {
//     const state = Cookies.get(key);
//     if (state) {
//       return JSON.parse(state);
//     }
//     return undefined;
//   },
//   // eslint-disable-next-line no-unused-vars
//   saveState: (key, state, storage) => {
//     Cookies.set(key, JSON.stringify(state), '3d');
//   },
//   modules: [],
// });
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['app', 'auth', 'organizations'],
});
Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  modules,
  plugins: [
    // vuexCookie.plugin,
    vuexLocal.plugin,
  ],
});

export default store;
