import 'core-js/stable';
import '@/styles/styles.scss';

import axios from 'axios';
import VueAxios from 'vue-axios';
import auroraModule from '@codehq/aurora-app-core/src/plugins/auroraModule';
import { Auth, AuthPlugin } from '@codehq/aurora-auth';
import { Client, ClientPlugin } from '@codehq/aurora-net';
import Vue from 'vue';
import App from './App.vue';
import SuccessButton from './components/SuccessButton.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { i18n } from './i18n';

Vue.config.productionTip = false;

// register global components
Vue.use(AuthPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
Vue.use(ClientPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});

Vue.use(VueAxios, axios);
Vue.component('SuccessButton', SuccessButton);

// setup store
const client = new Client({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});
store.$http = client;

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
store.$auth = auth;

// setup app plugins
Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
});

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
