import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('playBookModule', 'playBookModules');

const customActions = {
  async LOAD_playBookModules({ commit, rootGetters }, playBookId) {
    const data = await odata.o(rootGetters)
      .get('playBookModules')
      .query({
        $filter: `IsDeleted eq false AND PlayBookId eq ${playBookId}`,
      });
    commit('SET_playBookModules', data);

    return data;
  },
};

export default { ...actions, ...customActions };
