import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('moduleProcess', 'moduleProcesses');

const customActions = {
  async LOAD_moduleProcesses({ commit, rootGetters }, moduleId) {
    const data = await odata.o(rootGetters)
      .get('moduleProcesses')
      .query({ $filter: `IsDeleted eq false AND ModuleId eq ${moduleId}` });
    commit('SET_moduleProcesses', data);

    return data;
  },
};

export default { ...actions, ...customActions };
