import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('section', 'sections');

const customActions = {
  async LOAD_sections({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('sections')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Orgs($filter=IsDeleted eq false)',
        $orderby: 'Order',
      });
    commit('SET_sections', data);

    return data;
  },
  async LOAD_sectionsExpanded({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('sections')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'PlayBook,TestGuidePlayBook,Orgs',
      });
    commit('SET_section', data);

    return data;
  },
  async LOAD_sectionsForOrgs({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('sectionOrgs')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Section',
        $orderby: 'Section/Order',
      });
    const sections = data.map((x) => x.section);

    return sections;
  },
};

export default { ...actions, ...customActions };
