<template>
  <v-menu
    v-if="organizations.length > 0"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        icon
        :loading="isLoading"
        v-on="on"
      >
        <v-icon>{{ mdiOfficeBuilding }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="org in organizations"
        :key="org.id"
        @click="organizationChanged(org)"
      >
        <v-list-item-title>{{ org.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiOfficeBuilding } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import organizationSelectors from './organizationSelectors';

export default {
  name: 'OrganizationMenu',
  mixins: [loading, organizationSelectors],
  data() {
    return {
      /** icons */
      mdiOfficeBuilding,
    };
  },
  computed: {
    title() {
      return this.organization?.name ?? this.$t('common.organization.select');
    },
  },
};
</script>
