import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('page', 'pages');

const customActions = {
  async LOAD_page({ commit, rootGetters }, { name }) {
    const data = await odata.o(rootGetters)
      .get(`pages('${name}')`)
      .query();
    commit('SET_page', data);

    return data;
  },
  async UPDATE_page({ commit, rootGetters }, page) {
    const data = await odata.o(rootGetters)
      .patch(`pages('${page.name}')`, page)
      .query();
    commit('SET_page', data);

    return data;
  },
};

export default { ...actions, ...customActions };
