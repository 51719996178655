import { render, staticRenderFns } from "./SuccessButton.vue?vue&type=template&id=5b08d34e&scoped=true&"
import script from "./SuccessButton.vue?vue&type=script&setup=true&lang=js&"
export * from "./SuccessButton.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./SuccessButton.vue?vue&type=style&index=0&id=5b08d34e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b08d34e",
  null
  
)

export default component.exports