import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('transactionPlayBook', 'transactionPlayBooks');

const customActions = {
  async LOAD_transactionPlayBookExpanded({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`transactionPlayBooks(${id})`)
      .query({
        $expand: 'Modules($expand=Processes($filter=IsDeleted eq false;$expand=Tasks($filter=IsDeleted eq false)))',
      });
    commit('SET_transactionPlayBook', data);

    return data;
  },
  async LOAD_transactionsByPlayBook({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get('transactionPlayBooks')
      .query({
        $filter: `PlayBookId eq ${id}`,
        $expand: 'Modules($expand=Processes($filter=IsDeleted eq false;$expand=Tasks($filter=IsDeleted eq false)))',
      });
    commit('SET_transactionPlayBooks', data);

    return data;
  },
};

export default { ...actions, ...customActions };
