import Vue from 'vue';
import VueRouter from 'vue-router';
import { kebabCase } from 'case-anything';
import modules from '../modules';
import guards from './guards';

Vue.use(VueRouter);

const mapViews = (prefix, views) => {
  const viewsArr = Object.keys(views)
    .filter(v => views[v].name !== 'Home')
    .map(v => ({
      path: `/${prefix}/${kebabCase(v)}`,
      name: `${prefix}-${v}`,
      component: views[v],
      meta: views[v].meta,
    }));

  return viewsArr;
};

const routes = [
  {
    path: '/',
    redirect: '/page/Welcome',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/init',
    name: 'app-Init',
    component: () => import('@/views/Init.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/on-board',
    name: 'app-Onboard',
    component: () => import('@/views/Onboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/playbook/:id',
    name: 'playbooks-ViewPlayBook',
    component: () => import('@/views/playbooks/ViewPlayBook.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/playbooks',
    name: 'playbooks-PlayBooks',
    component: () => import('@/views/playbooks/PlayBooks.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/my-playbooks',
    name: 'playbooks-PlaybookHome',
    component: () => import('@/views/playbooks/PlaybookHome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/organisations',
    name: 'admin-ListOrg',
    component: () => import('@/views/admin/ListOrg.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/users',
    name: 'admin-Users',
    component: () => import('@/views/UserManagementHome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/manage-content',
    name: 'content-ManageContent',
    component: () => import('@/views/pages/ManageContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/sections',
    name: 'sections-ListSection',
    component: () => import('@/views/pages/sections/ListSections.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/section/:sectionId',
    name: 'sections-EditSection',
    component: () => import('@/views/pages/sections/EditSection.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/page/:name',
    name: 'page-ViewPage',
    component: () => import('@/views/pages/ViewPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/page/edit/:name',
    name: 'page-EditPage',
    component: () => import('@/views/pages/EditPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/section/:sectionId',
    name: 'sections-ViewSection',
    component: () => import('@/views/pages/sections/ViewSection.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

const moduleRoutes = modules.map(m => mapViews(m.config.path, m.views)).flat();

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes, ...moduleRoutes],
});

router.beforeEach(guards);

export default router;
