<template>
  <div />
</template>

<script>
import { mapState } from 'vuex';
import { useAuth } from '@/use';
import axios from 'axios';
import authHub from '@codehq/aurora-app-core/src/authHub';

export default {
  name: 'Auth',
  computed: {
    ...mapState('organizations', ['organization']),
  },
  destroyed() {
    this.$root.$off('auth:token');
    this.$root.$off('auth:logoff');
  },
  async created() {
    this.$log.debug('setting up listeners');
    authHub.$on('network:unauthorized', async () => {
      this.$store.dispatch('auth/LOGOUT_auth');
      this.$router.push({ name: 'auth-login' });
    });
    authHub.$on('network:forbidden', async () => {
      this.$log.error('forbidden');
      this.$root.$emit('toast:error', 'You are not authorized to perform this action');
    });
    this.$log.info('setting up axios');
    axios.interceptors.request.use(
      async config => {
        const { organization } = this;
        const token = await useAuth.getAccessToken();
        const result = { ...config };
        if (token) {
          result.headers.Authorization = `Bearer ${token}`;
        }
        if (organization) {
          result.headers.organizationid = `${organization?.id}`;
        }

        return config;
      },
      error => Promise.reject(error),
    );
    axios.interceptors.response.use(
      response => response,
      async error => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error) {
          if (error?.response?.status === 401) {
            this.$store.dispatch('auth/LOGOUT_auth');
            if (this.$route.name !== 'auth-login') {
              this.$log.info('redirecting to login');
              this.$router.push({ name: 'auth-login' });
            }
            this.$log.warn('unauthorized');
          } else {
            this.$log.error(error);
          }
        } else {
          this.$log.error('Error not found');
        }

        return Promise.reject(error);
      },
    );
  },
};
</script>
